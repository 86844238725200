import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Matches from "./components/Matches";
import Teams from "./components/Teams";
import Login from "./components/login";
import Logout from './components/logout';
import Layout from './layout/Layout';
import TipForm from './components/TipForm';
import UserProfile from './components/userProfile';
import MatchCard from './components/MatchCard';
import Rank from './components/Rank';

function App() {
  return (
    <Layout>
      <Routes>
          <Route path="/" element={<Matches />} />
          <Route path="/login" element={<Login />} />
          <Route path='/teams' element={<Teams />} />
          <Route path='/matches' element={<Matches />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/tipForm" element={<TipForm />} />
          <Route path="/userData" element={<UserProfile />} />
          <Route path="/matchCard" element={<MatchCard />} />
          <Route path="/rank" element={<Rank />} />
        </Routes>
      </Layout>
  );
}

export default App;
