import axios from "axios";
import React, { useEffect, useState } from "react";

//let baseURL = "http://localhost:6060";

function UserProfile(){
    const [userData, setUserData] = useState({});
    useEffect(() => {
        axios.post(process.env.REACT_APP_APIURL + "/fifa/server/getUser",{
            userID: window.sessionStorage.getItem('userToken')
        }).then((data)=>{
            setUserData(data.data.message);
        });
    }, []);
    return (
        <div>
            <p>{userData.userName}</p>
            <p>Teljes név: {userData.fullName}</p>
        </div>
    )
}

export default UserProfile;