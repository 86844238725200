import React , { createContext, useState } from 'react';

const MatchContext = createContext({
    matchID: -1,
    teamA: '',
    teamB: '',
    matchDate: '',
    tipGoalA: -1,
    tipGoalB: -1, 
    tipExist: false,
    add: (matchData) =>{},
});

export function MatchContextProvider(props){
    const [matchData, setMatchData] = useState({matchID: -1, teamA: '', teamB: '', matchDate: '', add: add, tipGoalA: -1, tipGoalB: -1, tipExist: false});

    function add(newMatchData){
        setMatchData(()=>{
            return newMatchData;
        });
    }

    const context = {
        matchID: matchData.matchID,
        teamA: matchData.teamA, 
        teamB: matchData.teamB, 
        matchDate: matchData.matchDate,
        tipGoalA: matchData.tipGoalA,
        tipGoalB: matchData.tipGoalB,
        tipExist: matchData.tipExist,
        add: add
    };

    return (<MatchContext.Provider value={context}>
        {props.children}
    </MatchContext.Provider>
    );
}

export default MatchContext;