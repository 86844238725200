//import MainNavigation from './MainNavigation';
import React from 'react';

import classes from './Layout.module.css';
import ResponsiveAppBar from './MainNavigation2';


function Layout(props){
    return (
        <div>
            <ResponsiveAppBar />
            <main className={classes.main}>{props.children}</main>
        </div>
    )
}

export default Layout;