import classes from './Rank.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import axios from 'axios';
import React from 'react';


function Rank(){
    const [points, setPoints] = useState([]);
    useEffect(() =>{
        axios.get(process.env.REACT_APP_APIURL + "/fifa/server/getPoints")
        .then((data) => {
            setPoints(data.data.message);
        })
        .catch((err) => {
            console.log(err.message);
        });
    }, []);
    return(
        <div>
            <div className={classes.header}>Állás</div>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Név</TableCell>
                        <TableCell>Pont</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {points.map((row) => (
                        <TableRow
                            key={row.userName}
                        >
                            <TableCell component="th" scope="row">
                                {row.userName}
                            </TableCell>
                            <TableCell>{row.point}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
        </div>
    )
}

export default Rank;