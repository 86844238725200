import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
//import classes from './Teams.module.css';
import { Grid, Card, CardContent, CardMedia } from '@mui/material';

//let baseURL = "http://localhost:6060";

function Teams(){

    let access = false;
    if(window.sessionStorage.getItem('userToken') > 0){
        access = true;
    }

    const [teamsArray, setTeams] = useState([]);
    useEffect(() =>{
        fetch(process.env.REACT_APP_APIURL+"/fifa/server/teamList")
            .then((response) => response.json())
            .then((data) => {
                setTeams(data.message);
            })
            .catch((err) => {
                console.log(err.message);
            });
        }, []
    );

    if(access === false){
        return(
            <Navigate replace to="/login" />
        )
    }

    return(
        <Grid container spacing={4}>
            {teamsArray.map((team) => {
                return (
                    <Grid item xs={4} key={team.Name}>
                        <Card>
                        <CardMedia
                            component="img"
                            height="15%"
                            image={team.FlagImage}
                            alt={team.Name}
                        />
                        <CardContent>
                            Név: {team.Name}<br />
                            Jelölés: {team.ShortName}<br/>
                            <a href={team.WikiLink}>Leírás</a><br />
                            Szövetségi kapitány: {team.NationalTeamCaptain}<br />
                            VB csoport: {team.WC_Group}<br />
                        </CardContent>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    );
}

export default Teams;