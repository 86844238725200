import React from 'react';

function Logout(){
    function logoutHandler(event){
        event.preventDefault();
        window.sessionStorage.setItem('userToken',undefined);
        window.location.href = "/login";
    }
    return (
        <div>
            <form onSubmit={logoutHandler}>
                <button>Logout</button>
            </form>
        </div>
    )
    
}

export default Logout;
