import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import classes from './Matches.module.css';
import axios from 'axios';
import MatchContext from "../store/matchContext";
import TipForm from "./TipForm";
import Backdrop from "./backdrop";
import MatchCard from "./MatchCard";
import { Grid } from '@mui/material';

//let baseURL = "http://localhost:6060";

function Matches(){
    const [ tipIsOpen, setTipIsOpen ] = useState(false);
    function deleteHandler(matchData){
        matchCtx.add(matchData);
        setTipIsOpen(true);
    }
    function closeModalHandler(){
        setTipIsOpen(false);
    }
    const matchCtx = useContext(MatchContext);
    let access = false;
    if(window.sessionStorage.getItem('userToken') > 0){
        access = true;
    }
    const [matchesArray, setMatches] = useState([]);
    useEffect(() =>{
        axios.get(process.env.REACT_APP_APIURL + "/fifa/server/matchList")
            .then((data) => {
                setMatches(data.data.message);
            })
            .catch((err) => {
                console.log(err.message);
            });
        }, []
    );
    if(access === false){
        return(
            <Navigate replace to="/login" />
        )
    }
    return(
        <div className={classes.cards}>
        <Grid container spacing={4}>
        {matchesArray.map((match) => {
            let localdate = new Date(match.Date).toLocaleString('hu-HU');
            return (
                    <MatchCard 
                        key={match.ID}
                        id={match.ID} 
                        date={localdate} 
                        teamA={match.TeamA} 
                        teamB={match.TeamB} 
                        goalA={match.GoalA}
                        goalB={match.GoalB}
                        onTip={deleteHandler}
                    />
            )
        })}
        </Grid>
        {tipIsOpen ? <TipForm onCancel={closeModalHandler} onConfirm={closeModalHandler}/> : null}
        {tipIsOpen && <Backdrop onClick={closeModalHandler}/>}
        </div>
    );
    /*
    return(
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Dátum</th><th>Meccs</th><th>Eredmény</th><th>Tippelek</th>
                    </tr>
                </thead>
                <tbody>
                {matchesArray.map((match) => {
                    return (
                        <tr key={match.ID}>
                            <td>{match.Date}</td>
                            <td>{match.TeamA} - {match.TeamB}</td>
                            <td></td>
                            <td><button onClick={()=>{deleteHandler({matchID: match.ID, teamA: match.TeamA, teamB:match.TeamB, matchDate: match.Date})}}>Gomb</button></td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {tipIsOpen ? <TipForm onCancel={closeModalHandler} onConfirm={closeModalHandler}/> : null}
            {tipIsOpen && <Backdrop onClick={closeModalHandler}/>}
        </div>
    );*/
}

export default Matches;