import React, { useRef, useState } from 'react';
import classes from './login.css';
import axios from 'axios';

var sha512 = require('js-sha512');

//let baseURL = "http://localhost:6060";

function Login(){
    const [successLogin, setSuccessLogin] = useState(false);
    const usernameInputRef = useRef();
    const passwordInputRef = useRef();
    function submitHandler(event){
        event.preventDefault();

        const enteredUsername = usernameInputRef.current.value;
        const enteredPassword = sha512(passwordInputRef.current.value);
        axios.post(process.env.REACT_APP_APIURL+"/fifa/server/login",{
            username: enteredUsername,
            password: enteredPassword
        }).then(data => {
            console.log(data);
            if(data.data.message >0){
                window.sessionStorage.setItem('userToken',data.data.message)
                setSuccessLogin(true);
            }else{
                window.sessionStorage.setItem('userToken','undefined')
                setSuccessLogin(false);
            }
            
        })
    }

    if (successLogin){       
        window.location.href = "/matches"; 
        return (
            <div></div>
        )
    }

    return(
        <div>
            <form className="login" onSubmit={submitHandler}>
                <div className={classes.control}>
                    <label htmlFor="username">Felhasználónév</label>
                    <input type="text" required id="username" ref={usernameInputRef} />
                </div>
                <div className={classes.control}>
                    <label htmlFor="password">Jelszó</label>
                    <input type="password" required id="password" ref={passwordInputRef} />
                </div>
                <div className={classes.action}>
                    <button>Bejelentkezés</button>
                </div>
            </form>
        </div>
    )
}

export default Login;