import React, { useContext, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import MatchContext from '../store/matchContext';
import classes from './TipForm.module.css';
import axios from 'axios';


//let baseURL = "http://localhost:6060";


function TipForm(props){
    //variables
    const teamAgoalRef = useRef();
    const teamBgoalRef = useRef();
    const matchContext = useContext(MatchContext);
    //check login
    let access = false;
    if(window.sessionStorage.getItem('userToken') > 0){
        access = true;
    }

    //functions
    function submitHandler(event){
        event.preventDefault();
        const enteredTeamAGoal = teamAgoalRef.current.value;
        const enteredTeamBGoal = teamBgoalRef.current.value;
        let url = process.env.REACT_APP_APIURL + "/fifa/server/addtip";
        if(matchContext.tipExist){
            url = process.env.REACT_APP_APIURL + "/fifa/server/modifytip";
        }
        axios.post(url,{
            matchID: matchContext.matchID,
            userID: window.sessionStorage.getItem('userToken'),
            goalA: enteredTeamAGoal,
            goalB: enteredTeamBGoal
        }).then(data => {
            console.log('');
        })

        props.onCancel();
    }
    function cancelHandler(){
        props.onCancel();
    }



    //return part
    if(access === false){
        return <Navigate replace to="/login" />
    }
    if (matchContext.matchID === undefined){
        return <Navigate replace to="/matches" />
    }
    console.log(matchContext);
    console.log(typeof matchContext.tipExist);
    return(
        <div className={classes.modal}>
            <form onSubmit={submitHandler}>
                <h2>
                    {matchContext.teamA} - {matchContext.teamB}
                </h2>
                <p>
                    {matchContext.matchDate}
                </p>
                <div>
                    <label>Tipp:</label>
                    <input ref={teamAgoalRef} type="number"/> : <input ref={teamBgoalRef} type="number"/>
                </div>
                {matchContext.tipExist ? <div>Előző tipped: {matchContext.tipGoalA} : {matchContext.tipGoalB}</div> : null}
                <button className={classes.btn} onClick={cancelHandler}>Bezár</button>
                <button>Küldés</button>
            </form>
        </div>
    )
}

export default TipForm;
