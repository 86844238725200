import axios from "axios";
import React, { useEffect, useState } from "react";
import classes from "./MatchCard.module.css";
import { Grid, Button, Card, CardContent, CardActions } from '@mui/material';


require('dotenv').config()

//let baseURL = "http://localhost:6060";

function MatchCard(props){
    const [ flagA, setFlagA ] = useState('');
    const [ flagB, setFlagB ] = useState('')
    const [tipGoalA, setTipGoalA] = useState(-1);
    const [tipGoalB, setTipGoalB] = useState(-1);
    const [tipExist, setTipExist] = useState(false);
    useEffect(() =>{
        axios.post(process.env.REACT_APP_APIURL + "/fifa/server/getTip",{
            userID: window.sessionStorage.getItem('userToken'),
            matchID: props.id
        })
        .then((data) => {
            setTipGoalA(data.data.message.goalTeamA);
            setTipGoalB(data.data.message.goalTeamB);
            if(data.data.message.goalTeamA !== -1){
                setTipExist(true);
            }
            
        })
        .catch((err) => {
            console.log(err.message);
        });
    });
    useEffect(() =>{
        axios.post(process.env.REACT_APP_APIURL+"/fifa/server/getFlag",{teamName:props.teamA})
            .then((data) => {
                if ("message" in data.data){
                    setFlagA(data.data.message.FlagImage);
                }else{
                    setFlagA(undefined);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    });
    useEffect(() =>{
        axios.post(process.env.REACT_APP_APIURL+"/fifa/server/getFlag",{teamName:props.teamB})
            .then((data) => {
                if("message" in data.data){
                    setFlagB(data.data.message.FlagImage);
                }else{
                    setFlagB(undefined);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    });
    return(
        <Grid item xs={12} sm={6} md={4}>
        <Card>
            <CardContent>
            <div className={classes.row}>
                {flagA ? <div className={classes.flag}><img src={flagA} alt={props.teamA}/></div> : null }
                <div className={classes.teamA}>{props.teamA} </div>
                -
                <div className={classes.teamB}> {props.teamB}</div>
                {flagB ? <div className={classes.flag}><img src={flagB}alt={props.teamB} /></div> : null }
            </div>
            <div className={classes.row}>
               Eredmény: {props.goalA} : {props.goalB}
            </div>
            <div className={classes.row}>
                Dátum: {props.date}
            </div>
            {tipExist ? <div className={classes.row}>Előző tippem: {tipGoalA} : {tipGoalB}</div> : null}
            </CardContent>
            <CardActions>
            <div className={classes.row}>
                <Button variant="contained" size="small" onClick={()=>{props.onTip({matchID: props.id, teamA: props.teamA, teamB:props.teamB, matchDate: props.date, tipExist:tipExist, tipGoalA:tipGoalA, tipGoalB:tipGoalB})}}>Tippelek</Button>
            </div>
            </CardActions>
        </Card>
        </Grid>
    )
}

export default MatchCard;